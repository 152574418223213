import React, { useState, useRef, useEffect, useCallback } from "react";

import Peer from 'skyway-js';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CircularProgress } from "@mui/material";


function Server() {


    const videoElm = useRef(null);
    const audioElm = useRef(null);
    const [isConnected, setIsConnected ] = useState(false)
    const [telNumber, setTelNumber] = useState("")
    const [videoDevices, setVideoDevices] = useState([])
    const [selectVideoDevice, setSelectVideoDevice] = useState("")
    const [isStart, setIsStart ] = useState(false)
    const [currentPeer, setPeer] = useState(null)
    const [isPermission, setIsPermission ] = useState(false)
    
    

    const handleConnect = useCallback(()=>{

        // if(!selectVideoDevice)
        // {
        //     alert("カメラを選択してください")    
        //     return
        // }

        const peer = new Peer(telNumber, { key: '6b7b501c-98b0-41c6-99c5-6ccd76511d1b',});
        setPeer(peer)

        let localStream;
        peer.on('open', () => {
            console.log("peer.id = " + peer.id)
            setIsConnected(true)
        });
        peer.on("close", () => {
            console.log("peer.close")
            setIsConnected(false)
        })

        console.log(selectVideoDevice)

        videoElm.current.play();
        audioElm.current.play();

        // カメラ映像取得
        navigator.mediaDevices.getUserMedia({ video: { 
            deviceId: selectVideoDevice
           }, audio: true })
            .then(stream => {
                // 成功時にvideo要素にカメラ映像をセットし、再生
                videoElm.current.srcObject = stream;
                videoElm.current.play();
                // 着信時に相手にカメラ映像を返せるように、グローバル変数に保存しておく
                localStream = stream;
            }).catch(error => {
                // 失敗時にはエラーログを出力
                console.error('mediaDevice.getUserMedia() error:', error);
                return;
            });

        //着信処理
        peer.on('call', mediaConnection => {
            console.log("called")
            mediaConnection.answer(localStream);
            
            mediaConnection.on('stream', stream => {

                console.log("on stream こないはず")
                // video要素にカメラ映像をセットして再生
                
                audioElm.current.srcObject = stream;
                audioElm.current.play();
              });

            mediaConnection.on("close", () => {
                console.log("mediaConnection.close")
                setIsConnected(false)
                
            })
        });

        setIsStart(true)

    }, [selectVideoDevice, telNumber]);

    const handlePermission = async()=>{

        videoElm.current.play();

        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        const devices = await navigator.mediaDevices.enumerateDevices();

        const _videoDevices = [];
        devices.forEach(function(device) {
            if(device.kind !== "videoinput")
                return;

            _videoDevices.push(device);
        });
        setVideoDevices(_videoDevices)


        setIsPermission(true);
    }

    

    //再接続ループ
    const [counter, setCounter] = useState(0);
    useEffect(
        () => {
            const id= setTimeout(() => {

                
                //開始していて && 切断中
                if(isStart && !isConnected && currentPeer)
                {
                    console.log("reconnect")
                    currentPeer.destroy()
                    handleConnect()
                }

                setCounter(counter + 1); 
            
            }, 2000);
            return () => {
                
                clearTimeout(id);
            };
        },
        [counter, currentPeer, isStart, isConnected, handleConnect],
    );



    return (
        <>
            { isStart && !isConnected &&
            <Box sx={{ zIndex:3, padding: 10, top: 0, left: 0, right: 0, bottom: 0, position: 'fixed', backgroundColor: '#ffffffcc', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

                <CircularProgress />
                <Box>再接続中</Box>

            </Box> }
            { !isPermission &&
                <Box sx={{ zIndex:2, padding: 10, top: 0, left: 0, right: 0, bottom: 0, position: 'fixed', backgroundColor: '#ffffffcc', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <Button size="large" variant="contained" fullWidth={true}
                        onClick={ handlePermission }
                    >セキュリティー許可</Button>
                </Box>
            }

            { isPermission && !isConnected &&
                <Box sx={{ zIndex:2, padding: 10, top: 0, left: 0, right: 0, bottom: 0, position: 'fixed', backgroundColor: '#ffffffcc', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">カメラ</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectVideoDevice}
                            label="カメラ"
                            onChange={(evn)=>{ setSelectVideoDevice(evn.target.value) }}
                        >

                            { videoDevices.map((device, index)=>(

                                <MenuItem key={index} value={device.deviceId}>{ device.label }</MenuItem>

                            )) }
                        </Select>
                    </FormControl>

                    <TextField id="outlined-basic" label="接続名" fullWidth={true} variant="outlined" value={telNumber} onChange={ (evn)=>{ setTelNumber(evn.target.value) } } />

                    <Box m={2} />

                    <Button size="large" variant="contained" fullWidth={true} onClick={ handleConnect }>開始</Button>
                    
                </Box> }

            <video ref={ videoElm } style={{ width: '100%', height: '100%', zIndex:1 }} muted playsInline />
            <audio ref={ audioElm } playsInline />
        </>
    );
}

export default Server;
