import React from "react";
import { BrowserRouter, Route } from 'react-router-dom';
import Client from './components/Client';
import Server from './components/Server';
import Home from './components/Home';

function App() {

    return (
        <BrowserRouter>
            <Route  exact path="/" component={Home} />
            <Route  exact path="/client" component={Client} />
            <Route  exact path="/server" component={Server} />
        </BrowserRouter>
    );
}

export default App;
