import React, { useState, useEffect, useRef, useCallback } from "react";

import Peer from 'skyway-js';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { CircularProgress } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function Client() {


    const videoElm = useRef(null);
    const [isConnected, setIsConnected ] = useState(false)
    const [telNumber, setTelNumber] = useState("")
    const [isStart, setIsStart ] = useState(false)
    const [currentPeer, setPeer] = useState(null)
    const [audioDevices, setAudioDevices] = useState([])
    const [isPermission, setIsPermission ] = useState(false)
    const [selectAudioDevice, setSelectAudioDevice] = useState("")
    const localStream = useRef()
    const [audioChecked, setAudioChecked] = useState(true);

    const handleAudioChange = (event) => {
        setAudioChecked(event.target.checked);

        var audioTrack = localStream.current.getAudioTracks()[0];
        audioTrack.enabled = event.target.checked
    };
    

    const handleConnect = useCallback(()=>{

        const init = async () => {

            console.log("handleConnect")

            videoElm.current.play();

            
            //ビデオ停止判定をリセット
            setVideoStopCount(0)

            
            localStream.current = await navigator.mediaDevices.getUserMedia({ audio: { 
                deviceId: selectAudioDevice
            }, video: true })
            

            //接続
            const peer = new Peer({ key: '6b7b501c-98b0-41c6-99c5-6ccd76511d1b' });
            setPeer(peer)



            peer.on('open', () => {

                console.log("peer.id = " + peer.id)

                const mediaConnection = peer.call(telNumber, localStream.current);
                mediaConnection.on('stream', stream =>
                {
                    console.log("on stream")
                    console.log(stream)
                    videoElm.current.srcObject = stream;
                    videoElm.current.play();

                    setIsConnected(true)
                });

                mediaConnection.on("close", () => {
                    console.log("mediaConnection.close")
                    setIsConnected(false)
                })
            })
            peer.on("close", () => {
                console.log("peer.close")
            })




            setIsStart(true);
        }
        init()

    },[telNumber, selectAudioDevice, ])


    const handlePermission = async()=>{


        await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        const devices = await navigator.mediaDevices.enumerateDevices();

        const _audioDevices = [];
        devices.forEach(function(device) {
            
            if(device.kind !== "audioinput")
                return;


            console.log(device)

            _audioDevices.push(device);
        });
        setAudioDevices(_audioDevices)


        setIsPermission(true);
    }


    useEffect(() => {

        console.log("useEffect")
        
        
          
    }, [currentPeer])


    const [counter, setCounter] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [videoStopCount, setVideoStopCount] = useState(0);

    useEffect(
        () => {
            const id= setTimeout(() => {

                // console.log("timer")
                // console.log([videoElm?.current.currentTime , currentTime])
                if(videoElm?.current.currentTime === currentTime)
                {
                    // console.log("stop? " + videoStopCount)
                    setVideoStopCount(videoStopCount + 1)
                    if(videoStopCount > 5)
                    {
                        if(isStart)
                            console.log("video stop!")
                        setIsConnected(false)

                        if(isStart){
                            console.log("reconnect")
                            currentPeer.destroy()
                            handleConnect()
                        }
                    }
                }
                setCurrentTime(videoElm?.current.currentTime)

                setCounter(counter + 1); 
            
            }, 1000);
            return () => {
                // console.log("timer clear")
                clearTimeout(id);
            };
        },
        [counter, currentTime, videoStopCount, currentPeer, handleConnect, isStart],
    );

    // console.log(currentPeer?.open)

    return (
        <>
            { isStart && !isConnected &&
            <Box sx={{ zIndex:3, padding: 10, top: 0, left: 0, right: 0, bottom: 0, position: 'fixed', backgroundColor: '#ffffffcc', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

                <CircularProgress />
                <Box>再接続中</Box>

            </Box> }


            { !isPermission &&
                <Box sx={{ zIndex:2, padding: 10, top: 0, left: 0, right: 0, bottom: 0, position: 'fixed', backgroundColor: '#ffffffcc', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <Button size="large" variant="contained" fullWidth={true}
                        onClick={ handlePermission }
                    >セキュリティー許可</Button>
                </Box>
            }

            { isPermission && !isConnected &&
            <Box sx={{ zIndex:3, padding: 10, top: 0, left: 0, right: 0, bottom: 0, position: 'fixed', backgroundColor: '#ffffffcc', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">音声</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectAudioDevice}
                        label="音声"
                        onChange={(evn)=>{ setSelectAudioDevice(evn.target.value) }}
                    >

                        { audioDevices.map((device, index)=>(

                            <MenuItem key={index} value={device.deviceId}>{ device.label }</MenuItem>

                        )) }
                    </Select>
                </FormControl>

                <TextField id="outlined-basic" label="接続先" fullWidth={true} variant="outlined" value={telNumber} onChange={ (evn)=>{ setTelNumber(evn.target.value) } } />

                <Box m={2} />

                <Button size="large" variant="contained" fullWidth={true} onClick={ handleConnect }>接続</Button>
                
            </Box> }

            <FormGroup>
            <FormControlLabel
                label="音声の送信"
                control={
                    <Checkbox checked={ audioChecked } onChange={ handleAudioChange } inputProps={{ 'aria-label': 'controlled' }} />
                }
             />
            </FormGroup>

            <video ref={ videoElm } style={{ zIndex:1, width: '100%', height: '100%' }} playsInline />
        </>
    );
}

export default Client;
