import React from "react";
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

function Home() {


    return (
        <>
            <Box sx={{ 
                    justifyContent: 'center',
                    display: 'flex',
                    flexWrap: 'wrap', 
                    flexDirection: 'column',
                    alignContent: 'center',
                    padding: 10, top: 0, left: 0, right: 0, bottom: 0, position: 'fixed', backgroundColor: '#ffffffcc', }}>
                <Button size="large" variant="contained" fullWidth={true} component={Link} to="/server" >カメラ側</Button>
                <Box mb={3} />
                <Button size="large" variant="contained" fullWidth={true} component={Link} to="/client">見る側</Button>

                <Box sx={{ m: 4 }} />

                <Divider></Divider>

                <Box sx={{ m: 4 }} />

                <Box sx={{ textAlign: 'center', }}>Ver 0.95</Box>
                音声が送信できるようになったバージョン
            </Box>
            
        </>
    );
}

export default Home;
